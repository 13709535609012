// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Context
import { Context } from 'context';

// Yup
import * as Yup from 'yup';

// Formik
import { Formik } from 'formik';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchTeams } from 'services/teams';
import { compareTeams } from 'services/compare';

// Constants
import { HIDE_TOP_TOOLBAR, HIDE_PAGINATION } from 'constants/mrt';
import { TEAMS_STATISTICS } from 'constants/statistic';
import { LOADING_TEXT } from 'constants/text';
import { DURATION_CELL, IMAGE_CELL, SET_CENTER_DATA_IN_COLUMN } from 'constants/tableHead';

// Schemas
import { BOOLEAN_SCHEMA, OBJECT_NULLABLE_SCHEMA } from 'schemas/base';
import { COMPARE_TEAMS_SCHEMA, COMPARE_STATISTICS_SCHEMA } from 'schemas/compare';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';
import { Box, Checkbox, FormControlLabel, Grid2 as Grid } from '@mui/material';
import MultiAutocomplete from 'components/atoms/MultiAutocomplete/MultiAutocomplete';
import DateRangePicker from 'components/molecules/DateRangePicker/DateRangePicker';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

const CompareTeamsView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [teams, setTeams] = useState([]);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Compare Teams';

  const fetchData = () => {
    setIsLoading(true);

    fetchTeams().then((res) => {
      setTeams(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);

      fetchData();
    }

    renderAfterCalled.current = true;
  }, []);

  const initialValues = {
    start: null,
    end: null,
    teams: [],
    statistics: [],
    scrims: false,
  };

  const validationSchema = Yup.object().shape({
    start: OBJECT_NULLABLE_SCHEMA,
    end: OBJECT_NULLABLE_SCHEMA,
    teams: COMPARE_TEAMS_SCHEMA,
    statistics: COMPARE_STATISTICS_SCHEMA,
    scrims: BOOLEAN_SCHEMA,
  });

  const table = useMaterialReactTable({
    columns,
    data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
  });
  const teamStaitistics = TEAMS_STATISTICS();

  return (
    <>
      <PageTitle title={title} />
      <Box my={2}>
        <Grid container>
          <Grid size={{ xs: 12, lg: 5, xl: 4 }}>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                compareTeams(values).then(({ data: respData }) => {
                  setData(respData.data);
                  setColumns(
                    respData.columns.map((col) => {
                      let properties = SET_CENTER_DATA_IN_COLUMN;

                      if (col.type === 'image') {
                        properties = IMAGE_CELL;
                      } else if (col.type === 'duration') {
                        properties = DURATION_CELL();
                      }

                      return { ...col, ...properties, size: col.size };
                    }),
                  );

                  setSubmitting(false);
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                setFieldValue,
                isSubmitting,
              }) => (
                <Box component="form" onSubmit={handleSubmit}>
                  <DateRangePicker
                    data={{
                      start: { name: 'start', label: 'Start Date', value: values.start },
                      end: { name: 'end', label: 'End Date', value: values.end },
                    }}
                    onChangeFn={setFieldValue}
                  />
                  <Grid container>
                    <Grid size={{ xs: 12 }}>
                      <MultiAutocomplete
                        disabled={teams.length === 0}
                        error={touched.teams && !!errors.teams}
                        helperText={touched.teams && errors.teams}
                        name="teams"
                        label="Teams"
                        options={teams}
                        value={values.teams}
                        onChangeFunction={setFieldValue}
                        onChangeField="teams"
                      />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <MultiAutocomplete
                        disabled={teamStaitistics.length === 0}
                        error={touched.statistics && !!errors.statistics}
                        helperText={touched.statistics && errors.statistics}
                        name="statistics"
                        label="Statistics"
                        options={teamStaitistics}
                        value={values.statistics}
                        onChangeFunction={setFieldValue}
                        onChangeField="statistics"
                      />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <FormControlLabel
                        control={<Checkbox id="scrims" name="scrims" />}
                        label="Include scrims"
                        checked={values.scrims}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Box mt={1}>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isSubmitting}
                      loadingIndicator={LOADING_TEXT}
                    >
                      Compare
                    </LoadingButton>
                  </Box>
                </Box>
              )}
            </Formik>
          </Grid>
          {data && data.length > 0 && (
            <>
              <Box component={Grid} size={{ lg: 1 }} display={{ xs: 'none', lg: 'block' }} />
              <Grid size={{ xs: 12, lg: 6 }}>
                <MaterialReactTable table={table} />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default CompareTeamsView;
