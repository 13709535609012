// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import { BLUE_MAIN } from 'constants/color';

// Assets
import DownloadIcon from '@mui/icons-material/Download';

// Elements
import { IconButton } from '@mui/material';

// Component
const DownloadData = ({ url }) => (
  <IconButton aria-label="download" onClick={() => window.open(url, '_blank')} size="small">
    <DownloadIcon sx={{ color: BLUE_MAIN, fontSize: 20 }} />
  </IconButton>
);

DownloadData.propTypes = {
  url: PropTypes.string.isRequired,
};

export default DownloadData;
