// Axios
import { axiosLoadingInstance } from 'utils/axios';

// Dayjs
import dayjs from 'dayjs';

export const fetchOverivew = async (
  itemType,
  side,
  result,
  splitId,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/overview/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (result !== undefined && result !== null) {
    url.searchParams.append('result', result);
  }

  if (splitId !== undefined && splitId !== null) {
    url.searchParams.append('split_id', splitId);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchDrafts = async (
  itemType,
  side,
  result,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
  draftFilter,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/drafts/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (result !== undefined && result !== null) {
    url.searchParams.append('result', result);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.post(url, draftFilter);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchDraftsPatterns = async (
  itemType,
  side,
  result,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/drafts/patterns/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (result !== undefined && result !== null) {
    url.searchParams.append('result', result);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchChampionPool = async (
  itemType,
  side,
  role,
  splitId,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
  playerMainRole,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/champion-pool/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (splitId !== undefined && splitId !== null) {
    url.searchParams.append('split_id', splitId);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  if (playerMainRole !== undefined && playerMainRole !== null) {
    url.searchParams.append('player_main_role', playerMainRole);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchFlex = async (
  itemType,
  side,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/flex/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSynergies = async (
  itemType,
  side,
  synergy,
  splitId,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/synergies/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (synergy !== undefined && synergy !== null) {
    url.searchParams.append('synergy', synergy);
  }

  if (splitId !== undefined && splitId !== null) {
    url.searchParams.append('split_id', splitId);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchPicks = async (
  itemType,
  dataType,
  side,
  role,
  splitId,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/picks/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (dataType !== undefined && dataType !== null) {
    url.searchParams.append('data_type', dataType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (splitId !== undefined && splitId !== null) {
    url.searchParams.append('split_id', splitId);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchEnemyData = async (
  itemType,
  dataType,
  side,
  role,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/enemy-data/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (dataType !== undefined && dataType !== null) {
    url.searchParams.append('data_type', dataType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchJungle = async (
  itemType,
  side,
  pathType,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/jungle/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (pathType !== undefined && pathType !== null) {
    url.searchParams.append('path_type', pathType);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchJungleStatistics = async (
  itemType,
  side,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/jungle/statistics/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchLvL1 = async (
  itemType,
  side,
  currentTime,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/lvl1/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (currentTime !== undefined && currentTime !== null) {
    url.searchParams.append('current_time', currentTime);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchLvL1Wards = async (
  itemType,
  side,
  role,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/lvl1/wards/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchShop = async (
  itemType,
  side,
  role,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/shop/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchWards = async (
  itemType,
  side,
  role,
  wardType,
  timerange,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/wards/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (wardType !== undefined && wardType !== null) {
    url.searchParams.append('ward_type', wardType);
  }

  if (timerange !== undefined && timerange !== null) {
    url.searchParams.append('timerange', timerange);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchWardsTimeline = async (
  itemType,
  side,
  role,
  wardType,
  timerange,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/wards/timeline/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (wardType !== undefined && wardType !== null) {
    url.searchParams.append('ward_type', wardType);
  }

  if (timerange !== undefined && timerange !== null) {
    url.searchParams.append('timerange', timerange);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchWardsSurvived = async (
  itemType,
  side,
  wardType,
  timerange,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/wards/survived/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (wardType !== undefined && wardType !== null) {
    url.searchParams.append('ward_type', wardType);
  }

  if (timerange !== undefined && timerange !== null) {
    url.searchParams.append('timerange', timerange);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchWardsObjectvies = async (
  itemType,
  side,
  objective,
  role,
  wardType,
  teamObjective,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/wards/objectives/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (objective !== undefined && objective !== null) {
    url.searchParams.append('objective', objective);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (wardType !== undefined && wardType !== null) {
    url.searchParams.append('ward_type', wardType);
  }

  if (teamObjective !== undefined && teamObjective !== null) {
    url.searchParams.append('team_objective', teamObjective);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchWardsStatistics = async (
  itemType,
  side,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/wards/statistics/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchControlWards = async (
  itemType,
  side,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/wards/control/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchOracleLens = async (
  itemType,
  side,
  role,
  timerange,
  teamId,
  startDate,
  endDate,
  killedWards,
  skippedWards,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/oracle-lens/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (timerange !== undefined && timerange !== null) {
    url.searchParams.append('timerange', timerange);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (killedWards !== undefined && killedWards !== null) {
    url.searchParams.append('killed_wards', killedWards);
  }

  if (skippedWards !== undefined && skippedWards !== null) {
    url.searchParams.append('skipped_wards', skippedWards);
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchHeatmap = async (
  itemType,
  side,
  roles,
  timerange,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/heatmap/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (roles !== undefined && roles !== null) {
    url.searchParams.append('roles', roles.map((role) => role.value).join(','));
  }

  if (timerange !== undefined && timerange !== null) {
    url.searchParams.append('timerange', timerange);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchPositionsOnMap = async (
  itemType,
  role,
  timerange,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/positions-on-map/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (timerange !== undefined && timerange !== null) {
    url.searchParams.append('timerange', timerange);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchObjectives = async (
  itemType,
  side,
  objective,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/objectives/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (objective !== undefined && objective !== null) {
    url.searchParams.append('objective', objective);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSummoners = async (
  itemType,
  side,
  role,
  summoner,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/summoners/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (summoner !== undefined && summoner !== null) {
    url.searchParams.append('summoner', summoner);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchTeamsStatistics = async (
  itemName,
  itemType,
  side,
  timerange,
  splitId,
  startDate,
  endDate,
  patches,
  opponents,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/teams-statistics/`);

  if (itemName !== undefined && itemName !== null) {
    url.searchParams.append('item_name', itemName);
  }

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (timerange !== undefined && timerange !== null) {
    url.searchParams.append('timerange', timerange);
  }

  if (splitId !== undefined && splitId !== null) {
    url.searchParams.append('split_id', splitId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchPlayersStatistics = async (
  itemType,
  role,
  side,
  timerange,
  splitId,
  startDate,
  endDate,
  patches,
  opponents,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/players-statistics/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (timerange !== undefined && timerange !== null) {
    url.searchParams.append('timerange', timerange);
  }

  if (splitId !== undefined && splitId !== null) {
    url.searchParams.append('split_id', splitId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchRecalls = async (
  itemType,
  side,
  role,
  timerange,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/recalls/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (role !== undefined && role !== null) {
    url.searchParams.append('role', role);
  }

  if (timerange !== undefined && timerange !== null) {
    url.searchParams.append('timerange', timerange);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchTPFlanks = async (
  itemType,
  side,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/potential-teleport-flank/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchGraphs = async (
  itemName,
  itemType,
  side,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/graphs/`);

  if (itemName !== undefined && itemName !== null) {
    url.searchParams.append('item_name', itemName);
  }

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchWinRate = async (
  itemType,
  side,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/win-rate/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchPerfromance = async (
  itemType,
  side,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/performance/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchScores = async (
  itemType,
  side,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/scores/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchHours = async (
  itemType,
  side,
  teamId,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/hours/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (teamId !== undefined && teamId !== null) {
    url.searchParams.append('team_id', teamId);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchPlayerOverivew = async (
  playerId,
  itemType,
  side,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
  playerMainRole,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/overview/player/${playerId}/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  if (playerMainRole !== undefined && playerMainRole !== null) {
    url.searchParams.append('player_main_role', playerMainRole);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchPlayerChampionPool = async (
  playerId,
  itemType,
  side,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
  playerMainRole,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/champion-pool/player/${playerId}/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  if (playerMainRole !== undefined && playerMainRole !== null) {
    url.searchParams.append('player_main_role', playerMainRole);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchPlayerStatistics = async (
  playerId,
  itemType,
  side,
  timerange,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
  playerMainRole,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/statistics/player/${playerId}/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (timerange !== undefined && timerange !== null) {
    url.searchParams.append('timerange', timerange);
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  if (playerMainRole !== undefined && playerMainRole !== null) {
    url.searchParams.append('player_main_role', playerMainRole);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchPlayerGraphs = async (
  playerId,
  itemName,
  itemData,
  itemType,
  side,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
  playerMainRole,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/graphs/player/${playerId}/`);

  if (itemName !== undefined && itemName !== null) {
    url.searchParams.append('item_name', itemName);
  }

  if (itemData !== undefined && itemData !== null) {
    url.searchParams.append('item_data', itemData);
  }

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  if (playerMainRole !== undefined && playerMainRole !== null) {
    url.searchParams.append('player_main_role', playerMainRole);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchPlayerHours = async (
  playerId,
  itemType,
  side,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
  playerMainRole,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/hours/player/${playerId}/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  if (playerMainRole !== undefined && playerMainRole !== null) {
    url.searchParams.append('player_main_role', playerMainRole);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};

export const fetchSummonersTracking = async (
  itemType,
  side,
  startDate,
  endDate,
  patches,
  opponents,
  blitz,
) => {
  const url = new URL(`${process.env.REACT_APP_API_URL}data/summoners-tracking/`);

  if (itemType !== undefined && itemType !== null) {
    url.searchParams.append('item_type', itemType);
  }

  if (side !== undefined && side !== null) {
    url.searchParams.append('side', side);
  }

  if (startDate !== undefined && startDate !== null) {
    url.searchParams.append('start_date', dayjs(startDate).format('YYYY-MM-DD'));
  }

  if (endDate !== undefined && endDate !== null) {
    url.searchParams.append('end_date', dayjs(endDate).format('YYYY-MM-DD'));
  }

  if (patches !== undefined && patches !== null) {
    url.searchParams.append('patches', patches.map((patch) => patch.label).join(','));
  }

  if (opponents !== undefined && opponents !== null) {
    url.searchParams.append('opponents', opponents.map((opponent) => opponent.value).join(','));
  }

  if (blitz !== undefined && blitz !== null) {
    url.searchParams.append('blitz', blitz);
  }

  try {
    const res = await axiosLoadingInstance.get(url);

    return res.data;
  } catch (e) {
    return { errorMessage: e, message: e.response.data.detail, error: 1 };
  }
};
