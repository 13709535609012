// Yup
import * as Yup from 'yup';

const MIN_PLAYERS = 2;
const MAX_PLAYERS = 5;

const MIN_TEAMS = 2;
const MAX_TEAMS = 10;

const MIN_STATISTICS = 1;
const MAX_STATISTICS = 10;

export const COMPARE_PLAYERS_SCHEMA = Yup.array()
  .min(MIN_PLAYERS, `You must choose at least ${MIN_PLAYERS} players!`)
  .max(MAX_PLAYERS, `You can choose up to ${MAX_PLAYERS} players!`)
  .required('Required');

export const COMPARE_TEAMS_SCHEMA = Yup.array()
  .min(MIN_TEAMS, `You must choose at least ${MIN_TEAMS} teams!`)
  .max(MAX_TEAMS, `You can choose up to ${MAX_TEAMS} teams!`)
  .required('Required');

export const COMPARE_STATISTICS_SCHEMA = Yup.array()
  .min(MIN_STATISTICS, `You must choose at least ${MIN_STATISTICS} statistics!`)
  .max(MAX_STATISTICS, `You can choose up to ${MAX_STATISTICS} statistics!`)
  .required('Required');
