// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Utils
import { secondsToHms } from 'utils/time';

// Constants
import { GREEN_MAIN, WHITE_MAIN } from 'constants/color';

// Assets
import InfoIcon from '@mui/icons-material/Info';

// Elements
import { Box, Tooltip, Typography } from '@mui/material';
import ImageItem from 'components/atoms/ImageItem/ImageItem';

// Component
const SummonersTrackingData = ({ cell }) => {
  const { getValue } = cell;

  return (
    <Box display="flex" gap={2.5}>
      <Tooltip
        title={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: 1,
            }}
          >
            {getValue()?.map(({ name, image_url, usage }) => (
              <Box key={name}>
                <ImageItem src={image_url} alt={name} width={32} height={32} />
                {usage.map(({ time, cooldown_back }) => (
                  <Box
                    key={time}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="p"
                      sx={{
                        fontSize: 12,
                        color: WHITE_MAIN,
                        mt: 1 / 2,
                      }}
                    >
                      {secondsToHms(time)}
                    </Typography>
                    <Typography
                      key={time}
                      variant="body2"
                      component="p"
                      sx={{
                        fontSize: 12,
                        color: GREEN_MAIN,
                        mt: 1 / 2,
                      }}
                    >
                      {secondsToHms(cooldown_back)}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        }
      >
        <InfoIcon fontSize="small" />
      </Tooltip>
    </Box>
  );
};

SummonersTrackingData.propTypes = {
  cell: PropTypes.object.isRequired,
};

export default SummonersTrackingData;
