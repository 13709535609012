// React
import React, { useEffect, useCallback, useRef } from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Heatmap.js
import * as heatMap from 'heatmap.js';

// Utils
import { drawWards } from 'utils/canvas';

// Constants
import { CANVAS_PROPORTION } from 'constants/canvas';

// Assets
import mapImage from 'assets/img/map.png';

// Styles
import { StyledMap, StyledCount, StyledCanvas } from 'assets/styles/Map.styles';

// Component
const Heatmap = ({ data, count = null, killedWards = [], skippedWards = [] }) => {
  const heatmapRef = useRef(null);
  const canvasRef = useRef(null);

  const drawMap = useCallback(() => {
    const mapCanvas = canvasRef.current;
    const heatmapBox = heatmapRef.current;
    const heatmapWidth = heatmapBox.clientWidth;
    const heatmapHeight = heatmapBox.clientHeight;
    const firstCanvas = heatmapBox.querySelector('canvas');

    if (firstCanvas) {
      firstCanvas.remove();
    }

    const heatmapInstance = heatMap.create({
      container: heatmapBox,
    });

    if (heatmapBox) {
      const scale = 1;
      const radius = 10 * scale;
      const newDataObject = {};

      data.forEach(({ x, y, ward: type }) => {
        if (type && type === 'unknown') {
          return;
        }

        const xc = Math.round(x * heatmapWidth * 100) / 100;
        const yc = heatmapHeight - Math.round(y * heatmapWidth * 100) / 100;
        const xcFixed = xc.toFixed(0);
        const ycFixed = yc.toFixed(0);
        const newKeyObj = `${xcFixed}|${ycFixed}`;

        if (!(newKeyObj in newDataObject)) {
          newDataObject[newKeyObj] = {
            x: xcFixed,
            y: ycFixed,
            value: 0,
            radius,
          };
        }

        newDataObject[newKeyObj].value += 1;
      });

      const newDataPoints = Object.values(newDataObject);
      const maxLimit = 15;

      let max = Math.max(...newDataPoints.map(({ value }) => value));

      if (max > maxLimit) {
        max = maxLimit;
      }

      const dataPoints = {
        max,
        data: newDataPoints,
      };

      heatmapInstance.setData(dataPoints);

      if (mapCanvas) {
        const mapItem = mapCanvas.parentElement;
        const mapWidth = mapItem.clientWidth;
        const mapHeight = mapItem.clientHeight;

        mapCanvas.width = mapWidth;
        mapCanvas.height = mapHeight;

        const ctx = mapCanvas.getContext('2d');
        const wardScale = Math.min(mapWidth, mapHeight) / CANVAS_PROPORTION;

        if (killedWards && killedWards.length > 0) {
          drawWards(ctx, killedWards, mapWidth, mapHeight, wardScale);
        }

        if (skippedWards && skippedWards.length > 0) {
          drawWards(ctx, skippedWards, mapWidth, mapHeight, wardScale);
        }
      }
    }
  }, [data, killedWards, skippedWards]);

  useEffect(() => {
    drawMap();

    const handleResize = () => {
      drawMap();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [drawMap]);

  return (
    <StyledMap>
      <StyledMap ref={heatmapRef} image={mapImage} heatmap>
        {count !== null && (
          <StyledCount variant="body2" component="p">
            {count}
          </StyledCount>
        )}
      </StyledMap>
      <StyledCanvas ref={canvasRef} />
    </StyledMap>
  );
};

Heatmap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  count: PropTypes.number,
  killedWards: PropTypes.arrayOf(PropTypes.any),
  skippedWards: PropTypes.arrayOf(PropTypes.any),
};

export default Heatmap;
