// React
import React, { useContext, useState, useEffect, useRef } from 'react';

// Router
import { useParams } from 'react-router-dom';

// Context
import { Context } from 'context';

// Services
import { fetchSingleTeamBySlug } from 'services/teams';
import { fetchOracleLens } from 'services/data';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import { Box, Checkbox, FormControlLabel, Grid2 as Grid, Typography } from '@mui/material';
import ImageTitle from 'components/molecules/ImageTitle/ImageTitle';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import Heatmap from 'components/atoms/Heatmap/Heatmap';

const TeamSingleOracleLensView = () => {
  const {
    setTitleSEO,
    filters: {
      team: {
        matchType,
        rangeDate,
        side,
        roleAll,
        timerange,
        patches,
        patches_data,
        opponents,
        opponents_data,
        blitz,
      },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const { teamSlug } = useParams();
  const [team, setTeam] = useState(null);
  const [title, setTitle] = useState('');
  const [data, setData] = useState([]);
  const [killedWards, setKilledWards] = useState([]);
  const [killedWardsInfo, setKilledWardsInfo] = useState(null);
  const [skippedWards, setSkippedWards] = useState([]);
  const [count, setCount] = useState(0);
  const [killedWardsOption, setKilledWardsOption] = useState(false);
  // const [skippedWardsOption, setSkippedWardsOption] = useState(false);
  const renderAfterCalled = useRef(false);

  const fetchInfo = (teamValue) => {
    setIsLoading(true);

    fetchSingleTeamBySlug(teamValue).then((res) => setTeam(res));
  };

  const fetchData = (
    itemType,
    sideData,
    roleData,
    timerangeData,
    teamId,
    startDateData,
    endDateData,
    killedWardsData,
    skippedWardsData,
    patchesData,
    opponentsData,
    blitzData,
  ) => {
    setIsLoading(true);

    fetchOracleLens(
      itemType,
      sideData,
      roleData,
      timerangeData,
      teamId,
      startDateData,
      endDateData,
      killedWardsData,
      skippedWardsData,
      patchesData,
      opponentsData,
      blitzData,
    ).then((res) => {
      setData(res.data);
      setCount(res.count);
      setKilledWards(res.killed_wards);
      setKilledWardsInfo(res.killed_wards_info);
      setSkippedWards(res.skipped_wards);

      setFilters((prevValue) => ({
        ...prevValue,
        team: {
          ...prevValue.team,
          patches_data: res.patches_data,
          opponents_data: res.opponents_data,
        },
      }));

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchInfo(teamSlug);
    }

    renderAfterCalled.current = true;
  }, [teamSlug]);

  useEffect(() => {
    if (team) {
      fetchData(
        matchType,
        side,
        roleAll,
        timerange,
        team.id,
        rangeDate[0],
        rangeDate[1],
        killedWardsOption,
        false,
        // skippedWardsOption,
        patches,
        opponents,
        blitz,
      );
    }
  }, [
    matchType,
    rangeDate,
    side,
    roleAll,
    timerange,
    team,
    killedWardsOption,
    // skippedWardsOption,
    patches,
    opponents,
    blitz,
  ]);

  useEffect(() => {
    if (team) {
      const tempTitle = `${team.name} Oracle Lens`;

      setTitle(tempTitle);
      setTitleSEO(tempTitle);
    }
  }, [team]);

  return (
    <ViewWithSidebar
      component={
        <>
          {team && <ImageTitle image={team?.image_url} name={team?.name} title={title} />}
          <DataFilters
            matchTypeFilter
            rangeDateFilter
            sideFilter
            roleAllFilter
            timerangeFilter
            patchesFilter={patches_data}
            opponentsFilter={opponents_data}
            blitzFilter
            type="team"
          />
          <Grid container>
            <Grid size={{ xs: 12, md: 6, lg: 5, xl: 4 }}>
              <Heatmap
                data={data}
                count={count}
                killedWards={killedWards}
                skippedWards={skippedWards}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6, lg: 7, xl: 8 }}>
              <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={killedWardsOption}
                        onChange={(e) => setKilledWardsOption(e.target.checked)}
                      />
                    }
                    label="Show killed wards"
                  />
                </Box>
                {killedWardsInfo && killedWardsOption ? (
                  <Box>
                    <Typography variant="body2" color="textSecondary">
                      Usage: {killedWardsInfo.usage}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Killed wards: {killedWardsInfo.wards}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Efficiency: {killedWardsInfo.value}%
                    </Typography>
                  </Box>
                ) : null}
              </Box>
              {/* <Box display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={skippedWardsOption}
                      onChange={(e) => setSkippedWardsOption(e.target.checked)}
                    />
                  }
                  label="Show skipped wards"
                />
              </Box> */}
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

export default TeamSingleOracleLensView;
