// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchTrData } from 'services/tr';

// Constants
import { HIDE_TOP_TOOLBAR, HIDE_PAGINATION } from 'constants/mrt';
import { TR_COLS } from 'constants/tableHead';

// Elements
import PageTitle from 'components/atoms/PageTitle/PageTitle';

const TrView = () => {
  const { setTitleSEO, setIsLoading } = useContext(Context);
  const [data, setData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'TR links';

  const fetchData = () => {
    setIsLoading(true);

    fetchTrData().then((res) => {
      setData(res);

      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!renderAfterCalled.current) {
      fetchData();

      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const columns = useMemo(() => TR_COLS, []);
  const table = useMaterialReactTable({
    columns,
    data,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
  });

  return (
    <>
      <PageTitle title={title} />
      <MaterialReactTable table={table} />
    </>
  );
};

export default TrView;
