// React
import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';

// Context
import { Context } from 'context';

// Material React Table
import { useMaterialReactTable, MaterialReactTable } from 'material-react-table';

// Services
import { fetchSummonersTracking } from 'services/data';

// Constants
import { MATCH_TYPES } from 'constants/match';
import {
  SUMMONER_PINGS_LIST_COLS,
  SUMMONER_TIMERS_LIST_COLS,
  TEAM_MATCHES_SUMMONERS_TRACKING_COLS,
  TEAM_GAMES_SUMMONERS_TRACKING_COLS,
} from 'constants/tableHead';
import { MATCHES_TABLE, HIDE_TOP_TOOLBAR, HIDE_PAGINATION } from 'constants/mrt';

// Elements
import ViewWithSidebar from 'components/organisms/ViewWithSidebar/ViewWithSidebar';
import DataFilters from 'components/organisms/DataFilters/DataFilters';
import PageTitle from 'components/atoms/PageTitle/PageTitle';

const ScrimsSummonersTrackingView = () => {
  const {
    setTitleSEO,
    filters: {
      scrims: { rangeDate, side, patches, patches_data, opponents, opponents_data, blitz },
    },
    setFilters,
    setIsLoading,
  } = useContext(Context);
  const [data, setData] = useState([]);
  const [pingData, setPingData] = useState([]);
  const [timerData, setTimerData] = useState([]);
  const renderAfterCalled = useRef(false);
  const title = 'Scrims Summoners Tracking';

  useEffect(() => {
    if (!renderAfterCalled.current) {
      setTitleSEO(title);
    }

    renderAfterCalled.current = true;
  }, []);

  const fetchData = async (
    itemType,
    sideData,
    startDateData,
    endDateData,
    patchesData,
    opponentsData,
    blitzData,
  ) => {
    setIsLoading(true);

    try {
      const response = await fetchSummonersTracking(
        itemType,
        sideData,
        startDateData,
        endDateData,
        patchesData,
        opponentsData,
        blitzData,
      );

      setData(response.data || []);
      setPingData([]);
      setTimerData([]);

      setFilters((prevValue) => ({
        ...prevValue,
        scrims: {
          ...prevValue.scrims,
          patches_data: response.patches_data,
          opponents_data: response.opponents_data,
        },
      }));
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (renderAfterCalled.current) {
      fetchData(MATCH_TYPES.SCRIM, side, rangeDate[0], rangeDate[1], patches, opponents, blitz);
    }
  }, [rangeDate, side, patches, opponents, blitz]);

  const dataColumns = useMemo(() => TEAM_MATCHES_SUMMONERS_TRACKING_COLS, []);
  const pingColumns = useMemo(() => SUMMONER_PINGS_LIST_COLS, []);
  const timerColumns = useMemo(() => SUMMONER_TIMERS_LIST_COLS, []);

  const dataTable = useMaterialReactTable({
    columns: dataColumns,
    data,
    ...MATCHES_TABLE(TEAM_GAMES_SUMMONERS_TRACKING_COLS, true),
  });

  const pingTable = useMaterialReactTable({
    columns: pingColumns,
    data: pingData,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
  });

  const timerTable = useMaterialReactTable({
    columns: timerColumns,
    data: timerData,
    ...HIDE_TOP_TOOLBAR,
    ...HIDE_PAGINATION,
  });

  return (
    <ViewWithSidebar
      component={
        <>
          <PageTitle title={title} />
          <DataFilters
            rangeDateFilter
            sideFilter
            patchesFilter={patches_data}
            opponentsFilter={opponents_data}
            blitzFilter
            type="scrims"
          />
          <PageTitle title="Game Summoners" variant="h6" component="h2" />
          <MaterialReactTable table={dataTable} />
          <PageTitle title="Summoner Pings" variant="h6" component="h2" mt={2} />
          <MaterialReactTable table={pingTable} />
          <PageTitle title="Summoner Timers" variant="h6" component="h2" mt={2} />
          <MaterialReactTable table={timerTable} />
        </>
      }
    />
  );
};

export default ScrimsSummonersTrackingView;
