// Axios
import { axiosInstance } from 'utils/axios';

export const comparePlayers = (data) =>
  axiosInstance
    .post('compare/players/', data)
    .then((res) => ({ data: res.data, message: 'Data have been found!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));

export const compareTeams = (data) =>
  axiosInstance
    .post('compare/teams/', data)
    .then((res) => ({ data: res.data, message: 'Data have been found!', error: 0 }))
    .catch((e) => ({ errorMessage: e, message: e.response.data.detail, error: 1 }));
