// React
import React, { useContext, useEffect, useRef } from 'react';

// Router
import { Link, useLocation } from 'react-router-dom';

// Context
import { Context } from 'context';

// Cookies
import { useCookies } from 'react-cookie';

// Notistack
import { useSnackbar } from 'notistack';

// Routes
import { trBasicURL } from 'routes/app';

// Services
import { checkMe } from 'services/users';

// Constants
import { COOKIE_NAME, COOKIES } from 'constants/cookie';
import { SUCCESS_SNACKBAR, ERROR_SNACKBAR } from 'constants/snackbar';
import { USER_SIGN_OUT_TEXT, TOKEN_EXPIRED_TEXT } from 'constants/text';

// Logo
import logo from 'assets/img/logo.svg';

// Assets
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

// Elements
import { Box, AppBar, Toolbar, Typography, Button } from '@mui/material';
import Menu from 'components/molecules/Menu/Menu';

// Component
const Header = () => {
  const { token, setToken, user, setUser, setCurrentTeamId } = useContext(Context);
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [cookie, , removeCookie] = useCookies([COOKIE_NAME]);
  const interval = useRef(null);

  const signOut = () => {
    removeCookie(COOKIE_NAME, COOKIES);

    setToken(null);
    setUser({});
    setCurrentTeamId(null);

    enqueueSnackbar(USER_SIGN_OUT_TEXT, {
      variant: SUCCESS_SNACKBAR,
    });
  };

  const fetchUserData = async () => {
    const { data } = await checkMe(cookie.token);

    if (data) {
      if (Object.keys(user).length === 0) {
        setUser(data);
        data?.teams_access.forEach(({ team_id, selected }) => {
          if (selected) setCurrentTeamId(team_id);
        });
      }
    } else {
      removeCookie(COOKIE_NAME, COOKIES);
      setToken(null);
      setUser({});
      enqueueSnackbar(TOKEN_EXPIRED_TEXT, {
        variant: ERROR_SNACKBAR,
      });
    }
  };

  useEffect(() => {
    if (token && cookie.token) {
      fetchUserData();
    }

    interval.current = setInterval(fetchUserData, 30000);

    return () => clearInterval(interval.current);
  }, [token]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed">
        <Toolbar>
          <Menu isAdmin={pathname.includes('/admin')} />
          <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
            <img src={logo} alt="LoLScout" width={32} height={32} />
            <Typography variant="h6" component="div" ml={1}>
              LoLScout
            </Typography>
          </Box>
          {token ? (
            <>
              {process.env.REACT_APP_DEV === '1' &&
              (user.role === 'ADMIN' || user.role === 'PLAYER') ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: 3,
                  }}
                >
                  <AccountBalanceWalletIcon fontSize="small" />
                  <Typography
                    variant="body2"
                    sx={{
                      ml: 1 / 2,
                    }}
                  >
                    {user.sum_points}
                  </Typography>
                </Box>
              ) : null}
              <Button
                component={Link}
                to={trBasicURL}
                variant="text"
                color="white"
                size="small"
                sx={{
                  mr: 3,
                }}
              >
                TR links
              </Button>
              <Button variant="text" color="white" size="small" onClick={signOut}>
                Sign out
              </Button>
            </>
          ) : null}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
